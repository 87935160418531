"use client";
import { useEffect, useState, type FC } from "react";
import { z } from "zod";
import Spinner from "~/modules/common/components/atoms/Spinner";
import { Text } from "~/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";
import { schemaPappersCompanyName } from "~/server/schemas/pappers";
import { type SiretInputValue } from "../../interfaces";
import { VALID_SIRET_LENGTH, schemaSiretInput } from "../../schemas";

interface InputSiretProps {
  setInputValue: (value: SiretInputValue | undefined) => void;
  className?: string;
  disabled?: boolean;
}

const InputSiret: FC<InputSiretProps> = ({
  className,
  setInputValue,
  disabled = false,
}) => {
  const [siretNumber, setSiretNumber] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const fetchCompanyName = async (
    siret: string,
  ): Promise<SiretInputValue | void> => {
    if (siret.length !== VALID_SIRET_LENGTH) return;

    try {
      setError(undefined);
      setIsLoading(true);

      const response = await fetch(
        `https://suggestions.pappers.fr/v2?q=${siret}&cibles=siret`,
      );

      const siretNumber = z.coerce.number().parse(siret);

      // This applies in case of 401 unauthorized due to quotas exceeded
      // ==> we authorize the user to continue the onboarding by saving the user input without company name
      if (!response.ok && response.status === 401) {
        return {
          siret: siretNumber,
          companyName: undefined,
        };
      }

      const data = schemaPappersCompanyName.parse(await response.json());

      // In case the siret is numerically correct but not found in pappers database
      // we block the onboarding by throwing an error
      if (!data.resultats_siret[0]) {
        throw new Error(
          "Nous ne trouvons pas d'entreprise liée à ce SIRET. Si la création de votre entreprise est récente, il est possible que les informations ne soient pas encore disponibles. Veuillez réessayer plus tard ou contactez nous si le problème persiste.",
        );
      }

      const formResponse = schemaSiretInput.parse({
        siret: data.resultats_siret[0].siege.siret,
        companyName: data.resultats_siret[0].nom_entreprise,
      });

      setError(undefined);

      return formResponse;
    } catch (error) {
      setError(
        new Error("Impossible de trouver l'entreprise liée au SIRET donné"),
      );

      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchCompanyName = async () => {
    if (siretNumber) {
      const result = await fetchCompanyName(siretNumber);
      if (result) {
        setInputValue(result);
      } else {
        setInputValue(undefined);
      }
    }
  };

  useEffect(() => {
    handleFetchCompanyName().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siretNumber]);

  return (
    <div className="relative">
      <input
        type="text"
        onChange={(e) => {
          setError(undefined);

          const stringValue = e.target.value;
          const numberValue = z.coerce.number().safeParse(stringValue);

          if (numberValue.success) {
            setSiretNumber(stringValue);
          } else {
            setError(new Error("Le numéro SIRET doit être un nombre"));
            setSiretNumber("");
          }
        }}
        value={siretNumber ?? ""}
        placeholder="Entrez votre numéro de SIRET..."
        className={cn(
          "bg-white flex h-[50px] w-full rounded-[8px] border border-new-dark-4 py-[10px] pl-[13px] pr-8 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-black/50 focus-visible:border-new-primary-1 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        disabled={disabled}
      />
      {isLoading && <Spinner className="absolute right-4 top-[14px] h-6 w-6" />}
      {error && (
        <Text size="tiny" className="my-[2px] ml-3 h-3 text-new-corail-0">
          {error.message}
        </Text>
      )}
    </div>
  );
};

export default InputSiret;
