import { type FC, type ReactNode, forwardRef } from "react";
import { textSizeClasses } from "src/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";
import IconRoundedCheck from "../icons/IconRoundedCheck";
import IconRoundedUnCheck from "../icons/IconRoundedUnCheck";

export interface InputButtonProps {
  checked?: boolean;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  checkedIcon?: ReactNode;
  uncheckedIcon?: ReactNode;
}

const InputButton: FC<InputButtonProps> = forwardRef<
  HTMLButtonElement,
  InputButtonProps
>(
  (
    {
      children,
      className,
      checked,
      onClick,
      checkedIcon = <IconRoundedCheck className="text-new-light-0" />,
      uncheckedIcon = <IconRoundedUnCheck className="text-new-primary-1" />,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        type="button"
        className={cn(
          "flex w-full items-center justify-between rounded-lg border border-new-light-0 px-4 py-5 placeholder:text-new-dark-0 focus-visible:border-new-primary-1 focus-visible:outline-none",
          textSizeClasses.bodyRegular,
          checked && "border-new-primary-1",
          className,
        )}
        ref={ref}
        onClick={onClick}
        {...props}
      >
        <>{children}</>
        <>
          {checked && <div>{checkedIcon}</div>}
          {!checked && <div>{uncheckedIcon}</div>}
        </>
      </button>
    );
  },
);
InputButton.displayName = "InputButton";

export { InputButton };
