import {
  AssetType,
  AssetUsageType,
  CaseType,
  PartnerClientType,
  PartnerCompanySize,
  PartnerLoanVolume,
  PartnerProfession,
  PartnerUsageCases,
  TaxType,
} from "@prisma/client";
import { ZodIssueCode, z } from "zod";
import { MAX_INT4 } from "~/static/constant";
import {
  baseErrorsWording,
  schemaSelect,
  schemaText,
  schemaTextArea,
} from "../common/schemas";
import { QuestionInlineSlug, QuestionSimpleSlug } from "./enums";
import { type CaseOnboardingType } from "./interfaces";
import { isValidSiret } from "./utils/numberUtils";

export const schemaBtn = z.union([z.string(), z.number()], {
  required_error: baseErrorsWording.required,
});

export const schemaNumber = z.coerce
  .number({
    invalid_type_error: "Ce champ doit être un nombre",
    required_error: baseErrorsWording.required,
  })
  .min(0, "La valeur doit être positive")
  .max(MAX_INT4, "La valeur est trop grande");

export const schemaSurface = z.coerce
  .number({
    invalid_type_error: "Ce champ doit être un nombre",
    required_error: baseErrorsWording.required,
  })
  .min(1, "La superficie du bien doit être d'au moins 1 m²")
  .max(MAX_INT4, "La valeur est trop grande");

export const schemaAddress = z.object(
  {
    address: z.string({
      required_error: "Le format de l'adresse est invalide",
    }),
    zipcode: z.string({
      required_error: "Le format du code postal est invalide",
    }),
    city: z.string({ required_error: "Le format de la ville est invalide" }),
    country: z.string({ required_error: "Le format du pays est invalide" }),
  },
  { required_error: "Le format de cette adresse est invalide" },
);

export type FormattedAddress = z.infer<typeof schemaAddress>;

export const schemaDate = z.coerce.date({
  errorMap: (issue, { defaultError }) => ({
    message:
      issue.code === ZodIssueCode.invalid_date
        ? baseErrorsWording.required
        : defaultError,
  }),
});

export const schemaEmail = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .email({ message: "Ce champ doit être une adresse email valide." });

export const schemaPassword = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .min(8, "Le mot de passe doit contenir au moins 8 caractères");

export const schemaPhone = z
  .string({
    required_error: baseErrorsWording.required,
  })
  .regex(/^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/, {
    message: "Ce champ doit être un numéro de téléphone valide",
  });

// SIRET schemas
export const VALID_SIRET_LENGTH = 14;

export const schemaSiretRequired = z.coerce
  .number({
    invalid_type_error: "Ce champ doit être un nombre",
    required_error: baseErrorsWording.required,
  })
  .refine((value) => value.toString().length === VALID_SIRET_LENGTH, {
    message: "Le numéro Siret doit contenir 14 caractères",
  })
  .refine(isValidSiret, {
    message: "Numéro Siret invalide",
  });

export const schemaSiret = schemaSiretRequired.optional();

export const schemaSiretInput = z.object({
  siret: schemaSiret,
  companyName: z.string().optional(),
});

export const schemaSiretInputRequired = z.object({
  siret: schemaSiretRequired,
  companyName: z.string().optional(),
});

export const schemaSimpleAnswerValue = z.union([
  schemaText,
  schemaAddress,
  schemaTextArea,
  schemaSelect,
  schemaDate,
  schemaEmail,
  schemaPhone,
  schemaSiretInput,
]);

export const schemaAnswerNested = z.object({
  questionSlug: z.nativeEnum(QuestionInlineSlug),
  value: z.optional(schemaSimpleAnswerValue),
});

export const schemaRepeatableAnswer = z.array(schemaAnswerNested);

export const schemaAnswer = z.object({
  questionSlug: z.nativeEnum(QuestionSimpleSlug),
  value: z.union([schemaSimpleAnswerValue, schemaRepeatableAnswer]),
  branchIteration: z.number(),
  categoryIteration: z.number(),
  isEmpty: z.optional(z.boolean()),
});

export const schemaAnswers = z.array(schemaAnswer);

export const schemaContactForm = z.object({
  firstName: schemaText,
  lastName: schemaText,
  phone: schemaPhone,
});

export const schemaCredentials = z.object({
  email: schemaEmail,
  password: schemaPassword,
});

export const schemaToken = z.object({ token: z.string() });

export const schemaPasswordRecovery = z.object({ email: schemaEmail });

export const schemaPasswordReset = z.object({
  password: schemaPassword,
  passwordConfirmation: schemaPassword,
});
export type PasswordReset = z.infer<typeof schemaPasswordReset>;

export const schemaPasswordUpdate = schemaPasswordRecovery
  .merge(schemaPasswordReset)
  .merge(schemaToken)
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "La confirmation du mot de passe ne correspond pas.",
    path: ["passwordConfirmation"],
  });

export type PasswordUpdate = z.infer<typeof schemaPasswordUpdate>;

export const schemaUserData = schemaContactForm.merge(schemaCredentials);

export const schemaQuestionType = z.enum([
  "btn",
  "btn-wrap",
  "text",
  "textarea",
  "select",
  "address",
  "number",
  "currency",
  "currency-positive",
  "date",
]);

export const schemaNavigation = z.object({
  branchSlug: z.string(),
  branchIteration: z.number(),
  categorySlug: z.string(),
  categoryIteration: z.number(),
  keepAnswers: z.optional(z.boolean()),
  exitPage: z.optional(z.enum(["company", "personal", "patrimonialSolo"])),
  redirect: z.optional(z.string()),
});

// Represents only onboarding linked to case creation
export const CaseOnboardingTypeEnum = z.enum([
  CaseType.standard,
  CaseType.patrimonial,
  CaseType.senior,
  CaseType.obo,
]);

// Represent all onboarding types
export const OnboardingTypeEnum = z.enum([
  ...CaseOnboardingTypeEnum.options,
  "partners",
]);

// Define the schema for data in local storage based on OnboardingCaseTypeEnum values
export const createGenericLocalStorageSchema = <T extends z.ZodTypeAny>(
  schemaItem: T,
) => {
  const shape = CaseOnboardingTypeEnum.options.reduce(
    (acc, key) => {
      acc[key] = schemaItem;
      return acc;
    },
    {} as Record<CaseOnboardingType, T>,
  );

  return z.object(shape);
};

export const schemaAnswersInLocalStorage =
  createGenericLocalStorageSchema(schemaAnswers);

export const assetUsageTypeSchema = z.enum([
  AssetUsageType.main_residence,
  AssetUsageType.secondary_residence,
  AssetUsageType.rental,
]);

export const assetTypeSchema = z.nativeEnum(AssetType);

export const taxTypeSchema = z.enum([TaxType.is, TaxType.ir]);

const schemaPartnerProfileBase = z.object({
  loanVolume: z.nativeEnum(PartnerLoanVolume),
  clientTypes: z.array(z.nativeEnum(PartnerClientType)),
  usageCases: z.array(z.nativeEnum(PartnerUsageCases)),
});

export const schemaPartnerProfileForm = z.discriminatedUnion("profession", [
  schemaPartnerProfileBase.extend({
    profession: z.literal(PartnerProfession.other),
    otherProfession: z
      .string()
      .min(1, "Ce champ est requis si profession est 'Autre'"), // Required when profession is "other"
  }),
  schemaPartnerProfileBase.extend({
    profession: z.enum(
      Object.values(PartnerProfession).filter(
        (p) => p !== PartnerProfession.other,
      ) as [Exclude<PartnerProfession, "other">],
    ),
  }),
]);

export const schemaPartnerCompanyForm = z.object({
  siret: schemaSiretRequired,
  companyName: z.string(),
  companyAddress: schemaAddress,
  workforce_min: z.number(),
  companySize: z.nativeEnum(PartnerCompanySize),
  orias: z
    .string()
    .transform((value) => (value.trim() === "" ? undefined : value)) // Transform empty string to undefined
    .optional()
    .refine((value) => !value || /^\d{8}$/.test(value), {
      message: "Le numéro ORIAS est composé de 8 chiffres",
    }), // Only validate if value exists
});
