"use client";
import { type FC, useEffect, useMemo, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { type Address } from "src/modules/onboarding/interfaces";
import { schemaAddress } from "src/modules/onboarding/schemas";
import {
  getAddress,
  getCityName,
  getCountry,
  getZipcode,
} from "src/utils/gmaps";
import { cn } from "~/modules/common/components/utils/styles";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import Spinner from "../Spinner";
import { Text } from "../Text";
import { IconCheck } from "../icons/IconCheck";
import { IconChevronsUpDown } from "../icons/IconChevronsUpDown";

interface InputAddressProps {
  setInputValue: (value: Address | undefined) => void;
  className?: string;
  initialValue?: Address;
  countryList?: string[];
  disabled?: boolean;
  placeholder?: string;
}

export const InputAddress: FC<InputAddressProps> = ({
  setInputValue,
  className,
  initialValue,
  countryList,
  disabled = false,
  placeholder,
}) => {
  const componentRestrictions = {
    country: countryList,
  };
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_REACT_APP_GOOGLE,
    debounce: 800,
    options: {
      language: "fr",
      types: ["geocode"],
      componentRestrictions: componentRestrictions,
    } as google.maps.places.AutocompletionRequest,
  });

  const [open, setOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.AutocompletePrediction | null>(null);
  const [selectedPlaceDetails, setSelectedPlaceDetails] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [error, setError] = useState<string | null>(null);

  const selectedPlaceDescription = useMemo(() => {
    if (selectedPlace) {
      return selectedPlace.description;
    }
    if (
      initialValue &&
      initialValue.address !== "" &&
      initialValue.city !== "" &&
      initialValue.country !== ""
    ) {
      return `${initialValue.address}, ${initialValue.city}, ${initialValue.country}`;
    }
    return null;
  }, [selectedPlace, initialValue]);

  useEffect(() => {
    if (selectedPlace) {
      setError(null);
      setSelectedPlaceDetails(null);
      placesService?.getDetails(
        {
          placeId: selectedPlace.place_id,
        },
        (placeDetails) => setSelectedPlaceDetails(placeDetails),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placesService, selectedPlace]);

  const formattedAddress = useMemo(() => {
    if (!selectedPlaceDetails) return undefined;
    const formattedAddress = schemaAddress.safeParse({
      address: getAddress(selectedPlaceDetails),
      zipcode: getZipcode(selectedPlaceDetails),
      city: getCityName(selectedPlaceDetails),
      country: getCountry(selectedPlaceDetails),
    });
    if (!formattedAddress.success) {
      setError(
        "Veuillez préciser l’adresse complète (numéro, nom de rue, code postal, ville, pays).",
      );
    }
    return formattedAddress.success ? formattedAddress.data : undefined;
  }, [selectedPlaceDetails]);

  useEffect(() => {
    if (formattedAddress) {
      setInputValue(formattedAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceDetails]);

  return (
    <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild disabled={disabled}>
          <button
            aria-expanded={open}
            className={cn(
              "flex w-full items-center justify-between rounded-lg border border-new-dark-4 bg-new-light-4 p-3 text-new-dark-0",
              error && "border-new-corail-0",
              className,
            )}
            disabled={disabled}
          >
            <Text
              size="bodyRegular"
              className={cn(
                "overflow-hidden overflow-ellipsis whitespace-nowrap",
                !selectedPlaceDescription && "text-new-dark-3",
              )}
            >
              {selectedPlaceDescription ??
                placeholder ??
                "Saisir l’adresse du bien"}
            </Text>
            <IconChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </button>
        </PopoverTrigger>
        <PopoverContent
          className="w-[var(--radix-popover-trigger-width)] bg-white-A700 p-0"
          align="start"
        >
          <Command
            shouldFilter={false}
            onChange={(e) =>
              getPlacePredictions({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                input: (e.target as any).value,
              })
            }
          >
            <CommandInput
              placeholder="Rechercher une adresse..."
              className="font-albertsans"
            />
            <CommandEmpty>
              {isPlacePredictionsLoading ? (
                <div className="flex h-full w-full items-center justify-center">
                  <Spinner className="h-4 w-4" />
                </div>
              ) : (
                <span>Aucune adresse trouvée.</span>
              )}
            </CommandEmpty>
            {placePredictions.length > 0 && (
              <CommandGroup>
                {placePredictions.map((placeItem) => (
                  <CommandItem
                    key={placeItem.place_id}
                    value={placeItem.place_id}
                    onSelect={() => {
                      setSelectedPlace(placeItem);
                      setOpen(false);
                    }}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <IconCheck
                      className={cn(
                        "mr-2 h-4 w-4 shrink-0",
                        selectedPlace?.place_id === placeItem.place_id
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {placeItem.description}
                  </CommandItem>
                ))}
              </CommandGroup>
            )}
          </Command>
        </PopoverContent>
      </Popover>
      {error && (
        <Text
          size="tiny"
          className="my-[1px] ml-3 h-3 font-semibold text-new-corail-0"
        >
          {error}
        </Text>
      )}
    </div>
  );
};
