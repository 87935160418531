import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";
import { type ComponentProps } from "react";
import { type CaptionProps, DayPicker, useNavigation } from "react-day-picker";
import {
  getYearListFromNow,
  monthNames,
} from "src/modules/common/utils/calendarUtils";
import { cn } from "~/modules/common/components/utils/styles";
import { buttonVariants } from "../atoms/buttons/ButtonShadcn";
import { ScrollArea } from "../atoms/ScrollArea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../atoms/Select";

const DATE_YEAR_RANGE = 30;

export type CalendarProps = ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("bg-white-A700 p-3 font-albertsans", className)}
      classNames={{
        month: "space-y-4",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-slate-500 rounded-md w-9 font-normal text-[0.8rem] dark:text-slate-400",
        row: "flex w-full mt-2",
        cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-green-5/50 [&:has([aria-selected])]:bg-green-5 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 dark:[&:has([aria-selected].day-outside)]:bg-slate-800/50 dark:[&:has([aria-selected])]:bg-slate-800",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
        ),
        day_range_end: "day-range-end",
        day_selected:
          "bg-green-2 text-slate-50 hover:bg-green-2 hover:text-slate-50 focus:bg-green-2 focus:text-slate-50 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50 dark:hover:text-slate-900 dark:focus:bg-slate-50 dark:focus:text-slate-900",
        day_today:
          "bg-green-5 text-slate-900 dark:bg-slate-800 dark:text-slate-50",
        day_outside:
          "day-outside text-slate-500 opacity-50 aria-selected:bg-green-5/50 aria-selected:text-slate-500 aria-selected:opacity-30 dark:text-slate-400 dark:aria-selected:bg-slate-800/50 dark:aria-selected:text-slate-400",
        day_disabled: "text-slate-500 opacity-50 dark:text-slate-400",
        day_range_middle:
          "aria-selected:bg-green-5 aria-selected:text-slate-900 dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        Caption: CustomCaption,
      }}
      {...props}
    />
  );
}

export { Calendar };

function CustomCaption({ displayMonth }: CaptionProps) {
  const { goToMonth } = useNavigation();

  const handleChangeMonth = (value: string) => {
    const newMonthIndex = monthNames.indexOf(value);
    const currentYear = displayMonth.getFullYear();
    const newDate = new Date(currentYear, newMonthIndex);
    goToMonth(newDate);
  };

  const handleChangeYear = (value: string) => {
    const newDate = new Date(parseInt(value), displayMonth.getMonth());
    goToMonth(newDate);
  };

  const years = getYearListFromNow(DATE_YEAR_RANGE);

  return (
    <div className="flex w-full items-center gap-4">
      <Select
        value={format(displayMonth, "MMMM")}
        onValueChange={(value) => handleChangeMonth(value)}
      >
        <SelectTrigger className="pr-1.5">
          <SelectValue>
            {format(displayMonth, "MMMM", { locale: frLocale })}
          </SelectValue>
        </SelectTrigger>
        <SelectContent position="popper" className="scroll-area">
          <ScrollArea className="h-80 bg-white-A700">
            {monthNames.map((month, id) => (
              <SelectItem key={`month-${id}`} value={month}>
                {month}
              </SelectItem>
            ))}
          </ScrollArea>
        </SelectContent>
      </Select>

      <Select
        value={displayMonth.getFullYear().toString()}
        onValueChange={(value) => handleChangeYear(value)}
      >
        <SelectTrigger className="pr-1.5">
          <SelectValue>{displayMonth.getFullYear().toString()}</SelectValue>
        </SelectTrigger>
        <SelectContent position="popper" className="scroll-area">
          <ScrollArea className="h-80 bg-white-A700">
            {years.map((year, id) => (
              <SelectItem key={`year-${id}`} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </ScrollArea>
        </SelectContent>
      </Select>
    </div>
  );
}
